import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const PaymentLoading = () => {
    let arr = [1,2,3,4];
    let rows = arr.map((i) => {
        return <div className="gold-members p-3 border-bottom" key={i}>
            <span className="float-right"> <Skeleton height="25px" width="40px" widthRandomness="0"/></span>
            <div className="media">
                <div className="media-body">
                <h6 className="mb-1 text-gray-800"><Skeleton/></h6>
                <p className="text-secondary mb-0"><Skeleton/></p>
                </div>
            </div>
        </div>
    });
    return <div className="bg-white rounded shadow-sm">
        {rows}
    </div>
}