import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import AccountTabs from './tabs';
import { db } from './../actions/settings';
import { login, updateMetaTags, cancelSub } from '../actions';

class Account extends React.Component {

    state = {
        user: this.props.user.info,
        loading: false,
        error: '',
        saved: false,
        cancelled: false
    }

    componentDidMount = async() => {
        window.$(".scroll-to-top").click();
        if (!this.props.user.loggedIn || !this.props.user.info) {
            this.props.history.push("/account/login");
        } else {
            this.props.updateMetaTags({
                title: 'My BoxUp Account',
                description: "Checkout your account details and more.",
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: 'BoxUp, dropship, account, Shopify'
                    }
                }
            });
            let doc = await db.collection("Affiliates").doc(this.props.user.info.id).get();
            let user = {
                id: doc.data().id,
                firstname: doc.data().firstname,
                lastname: doc.data().lastname,
                paid: doc.data().paid,
                stripeConnected: doc.data().stripeConnected,
                email: doc.data().email
            }
            this.props.login(user);
            this.setState({user});
        }
    }

    updateUserState = (key, val) => {
        let user = this.state.user;
        user[key] = val;
        this.setState({user});
    }

    updateUser = async(e) => {
        e.preventDefault();
        let { id, firstname, lastname } = this.state.user;
        this.setState({loading: true}, async() => {
            try {
                let data = this.props.user.info;
                const userData = { ...data, firstname: firstname, lastname: lastname };
                await db.collection("Affiliates")
                    .doc(id)
                    .update(userData);
                    this.props.login(userData);
                    this.setState({
                        loading: false,
                        saved: true
                    });
            } catch(e) {
                this.setState({error: e.message, loading: false});
            }
        });
    }

    subscribe = () => {
        if (!this.props.user.loggedIn || !this.props.user.info) {
            this.props.history.push("/account/login");
        }
        window.$("#subscribe").modal("show");
    }
    
    cancelSubscription = async() => {
        this.setState({loading: true}, async() => {
            let data = this.props.user.info;
            await cancelSub(data.id);
            let userData = { ...data, paid: false };
            this.props.login(userData);
            this.setState({
                loading: false,
                cancelled: true,
                user: userData
            });
            window.$("#cancel").modal("hide");
        });
        
    }

    render() {
        let user = this.state.user;
        return (
            <>
                <section className="user-panel-body py-5">
                    <div className="container">
                        <div className="row">
                            <AccountTabs/>
                            <div className="col-xl-9 col-sm-8">
                                <div className="user-panel-body-right">
                                    <div className="user-panel-tab-view mb-4">
                                        <div className="shadow-sm rounded overflow-hidden mb-3">
                                            {this.state.cancelled &&
                                            <div className="alert alert-primary" role="alert">
                                                <p>
                                                    <b>Your subscription has been cancelled.</b>
                                                </p>
                                                <p className="mt-2">
                                                    You will receive a refund for the remaining days left on the month you paid, if there are any.
                                                </p>
                                            </div>
                                            }
                                            <div className="p-4 bg-white">
                                                <h5 className="mb-0 text-gray-900">My Membership</h5>
                                                <div className="row d-flex">
                                                    <div className="col-sm-12">
                                                        {!user.paid &&
                                                        <>
                                                        <p className="mt-2">
                                                            You do not currently have an active plan. 
                                                            Subscribe to be able to receive commission per sale.
                                                        </p>
                                                        <p className="mt-2">
                                                            The membership is $19 USD per month. See <a href="#" className="cursor-pointer" onClick={this.subscribe}>Pricing</a> for more information.
                                                        </p>
                                                        <Link 
                                                            className="btn btn-primary" 
                                                            to="/account/subscribe"
                                                        > 
                                                            Subscribe 
                                                        </Link>
                                                        </>
                                                        }
                                                        {user.paid &&
                                                        <>
                                                        <p className="mt-2">
                                                            You have a active subscription. You can now find products to sell.
                                                        </p>
                                                        <p className="mt-2">
                                                            If you're unsure where to go from here, please check our <Link to="/how-it-works">How It Works</Link> guide.
                                                        </p>
                                                        <span className="btn btn-success btn-icon-split">
                                                            <span className="icon text-white-50">
                                                                <i className="fas fa-check"></i>
                                                            </span>
                                                            <span className="text">Subscription Active</span>
                                                        </span>
                                                        {this.state.loading && 
                                                        <button type="button" className="btn btn-text">
                                                            <ClipLoader
                                                                size={24}
                                                                color={"#ffffff"}
                                                                loading={this.state.loading}
                                                            />
                                                        </button>
                                                        }
                                                        {!this.state.loading && 
                                                        <button onClick={() => window.$("#cancel").modal("show")} className="btn btn-text">
                                                            Cancel Subscription
                                                        </button>
                                                        }
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-panel-body-right">
                                    <div className="user-panel-tab-view mb-4">
                                        <div className="shadow-sm rounded overflow-hidden mb-3">
                                            <div className="p-4 bg-white">
                                                <h5 className="mb-0 text-gray-900">My Details</h5>
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-md-12">
                                                        <div className="mb-4">
                                                            <p className="text-muted">
                                                                Keep your details up to date here.
                                                            </p>
                                                        </div>
                                                        <form onSubmit={this.updateUser}>
                                                            <div className="form-row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="mb-1">First Name <small className="text-danger">*</small></label>
                                                                            <div className="position-relative icon-form-control">
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control"
                                                                                name="firstname"
                                                                                required
                                                                                value={this.state.user.firstname}
                                                                                onChange={e => this.updateUserState('firstname', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="mb-1">Last Name <small className="text-danger">*</small></label>
                                                                            <div className="position-relative icon-form-control">
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control"
                                                                                value={this.state.user.lastname}
                                                                                name="lastname"
                                                                                required
                                                                                onChange={e => this.updateUserState('lastname', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.error !== "" &&
                                                            <div className="alert alert-danger" role="alert">
                                                                <p>{this.state.error}</p>
                                                            </div>
                                                            }
                                                            {this.state.saved &&
                                                            <div className="alert alert-success" role="alert">
                                                                <p>Your details have been updated.</p>
                                                            </div>
                                                            }
                                                            <div className="d-flex align-items-center mt-4">
                                                                {this.state.loading && 
                                                                <button type="button" className="btn btn-primary text-uppercas">
                                                                    <ClipLoader
                                                                        size={24}
                                                                        color={"#ffffff"}
                                                                        loading={this.state.loading}
                                                                    />
                                                                </button>
                                                                }
                                                                {!this.state.loading && 
                                                                <button className="btn btn-primary text-uppercas" type="submit"> Update </button>
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="modal fade" id="subscribe" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">BoxUp Dropshipping Membership</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6>A fixed monthly fee of $19</h6>
                            <ul className="list-group list-group-flush mb-4">
                                <li className="list-group-item py-2 px-0 border-0">
                                    <i className="icofont-check-circled text-success mr-1"></i> 
                                    Unlimited sales
                                </li>
                                <li className="list-group-item py-2 px-0 border-0">
                                    <i className="icofont-check-circled text-success mr-1"></i> 
                                    Earn commission every sale
                                </li>
                                <li className="list-group-item py-2 px-0 border-0">
                                    <i className="icofont-check-circled text-success mr-1"></i> 
                                    Money deposited into your bank account
                                </li>
                                <li className="list-group-item py-2 px-0 border-0">
                                    <i className="icofont-check-circled text-success mr-1"></i> 
                                    No hosting required
                                </li>
                                <li className="list-group-item py-2 px-0 border-0">
                                    <i className="icofont-check-circled text-success mr-1"></i> 
                                    Get notified for each sale
                                </li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                data-dismiss="modal">Close</button>
                            <Link to="/account/subscribe" className="btn btn-primary">Subscribe</Link>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="cancel" role="dialog"
                    aria-labelledby="cancel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancel Dropshipping Membership</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to cancel your subscription?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                data-dismiss="modal">Close</button>
                            {!this.state.loading &&
                            <button 
                                ype="button" 
                                className="btn btn-danger"
                                onClick={this.cancelSubscription}
                            >Cancel</button>
                            }
                            {this.state.loading &&
                                <button type="button" className="btn btn-danger">
                                    <ClipLoader
                                        size={20}
                                        color={"#ffffff"}
                                        loading={this.state.loading}
                                    />
                                </button>
                            }
                        </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    login,
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    actions
)(Account);