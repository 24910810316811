import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { HomeLoading } from './loading';
import { getProducts } from './../actions/';
import { ProductListing } from './../products/listing';

class Home extends React.Component {

    state = {
        newest: [],
        loading: true,
        search: '',
        searchType: 'products'
    }

    componentDidMount = async() => {
        window.$(".scroll-to-top").click();
        let newest = await getProducts(4, 0);
        this.setState({
            loading: false,
            newest
        });
    }
    
    render() {
        return (
            <>
                <section className="section-padding homepage-search-block position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mx-auto pt-lg-5">
                                <div className="homepage-search-title text-center">
                                <h1 className="mb-3 text-shadow text-gray-900 font-weight-bold">
                                    Dropshipping Made <span className="font-weight-light">Easy</span>
                                </h1>
                                <h5 className="mb-5 text-shadow text-gray-800 font-weight-normal">
                                    Pick and choose products from Shopify stores around the world.
                                </h5>
                                </div>
                                <div className="homepage-search-form">
                                    <form className="form-noborder">
                                        <div className="form-row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <div className="location-dropdown">
                                                    <select 
                                                        className="custom-select form-control-lg"
                                                        onChange={(e) => this.setState({searchType: e.target.value})}
                                                    >
                                                        <option value="products"> Products </option>
                                                        <option value="stores"> Stores </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-12 form-group">
                                                <input 
                                                    type="text" 
                                                    placeholder="Search for products or stores"
                                                    onChange={(e) => this.setState({search: e.target.value})}
                                                    className="form-control border-0 form-control-lg shadow-sm"
                                                />
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                                <button 
                                                    type="button"
                                                    onClick={() => this.props.history.push(`/search/${this.state.searchType}?q=${this.state.search}`)}
                                                    className="btn btn-primary btn-block btn-lg btn-gradient shadow-sm"
                                                >
                                                    <i className="icofont-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <h6 className="mb-0 mt-1 text-shadow text-center text-gray-500-700 font-weight-normal">
                                    e.g. clothing, jewellery or technology
                                </h6>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-padding homepage-view-offers">
                    <div className="container">
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h5 mb-0 text-gray-900">Newest Products</h1>
                            <Link to="/product/newest" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                                <i className="fas fa-eye fa-sm text-white-50"></i> View All
                            </Link>
                        </div>
                        <div className="row">
                            {this.state.loading && 
                            <HomeLoading col={"3"}/>
                            }
                            {!this.state.loading && this.state.newest.map((item) => {   
                                return <ProductListing col={3} item={item} key={item.objectID}/>
                            })}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(Home);