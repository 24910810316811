const API_URL = process.env.REACT_APP_API_URL;

export async function getProducts(hitsPerPage, page, query, tags) {
    return new Promise(async(resolve, reject) => {
        try {
            let q = (query) ? query : '';
            let searchTags = '';
            if (tags && tags.length > 0) {
                searchTags = '('
                tags.map((tag) => {
                    searchTags = searchTags + `_tags:${tag} OR `;
                });
                searchTags = searchTags.substr(0, searchTags.length-3);
                searchTags = searchTags + ') AND ';
                
            }
            const algoliasearch = require("algoliasearch");
            const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
            const index = client.initIndex('ProductListings');
            let search = await index.search(q, {
                hitsPerPage: hitsPerPage,
                page: page,
                filters: searchTags + 'active:true',
            });
            resolve(search.hits);
        } catch(e) {
            reject(e);
        }
    });
}

export async function getStoreProducts(hitsPerPage, page, id) {
    return new Promise(async(resolve, reject) => {
        try {
            const algoliasearch = require("algoliasearch");
            const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
            const index = client.initIndex('ProductListings');
            let search = await index.search('', {
                hitsPerPage: hitsPerPage,
                page: page,
                filters: `shopId:"${id}" AND active:true`,
            });
            resolve(search.hits);
        } catch(e) {
            reject(e);
        }
    });
}

export async function getStores(hitsPerPage, page, query, tags) {
    return new Promise(async(resolve, reject) => {
        try {
            let q = (query) ? query : '';
            let searchTags = '';
            if (tags && tags.length > 0) {
                searchTags = '('
                tags.map((tag) => {
                    searchTags = searchTags + `_tags:${tag} OR `;
                });
                searchTags = searchTags.substr(0, searchTags.length-3);
                searchTags = searchTags + ') AND ';
                
            }
            const algoliasearch = require("algoliasearch");
            const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
            const index = client.initIndex('Stores');
            let search = await index.search(q, {
                hitsPerPage: hitsPerPage,
                page: page,
                filters: searchTags + 'active:true',
            });
            resolve(search.hits);
        } catch(e) {
            reject(e);
        }
    });
}

export async function getProduct(objectID) {
    const algoliasearch = require("algoliasearch");
    const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
    const index = client.initIndex('ProductListings');
    let product = await index.getObject(objectID);
    return product;
}

export function differenceInDays(start, end) {
    let diffMs = (start - end);
    let days = Math.floor(diffMs / 86400000);
    let txt = (days === 1) ? 'day' : 'days';
    return (days < 1) ? 'Today' : `${days} ${txt} ago`;
}

export function getProductDetails(shop, handle) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/shop/product?shop=${shop}&handle=${handle}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function getStoreDetails(domain) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/shop/details?domain=${domain}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function getDashboardLink(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/dropship/link?id=${id}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function createStripeAccount(id, code, email) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/dropship/connect?id=${id}&code=${code}&email=${email}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function confirmSubscription(sessionId, id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/dropship/confirm?session=${sessionId}&id=${id}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function createSession(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/dropship/session?id=${id}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function cancelSub(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/dropship/cancel?id=${id}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function sendContact(data) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/dropship/contact`;
        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify(data)
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function getCommission(commission) {
    let currency = (commission.formattedPrice).substr(0,1);
    let highPerc = 0, highPercPrice = 0;
    let highFixed = 0, highFixedPrice = 0;
    if (commission.type === "fixed") {
        if (highFixed < Number(commission.value)) {
            highFixed = Number(commission.value);
            highFixedPrice = Number(commission.price);
        }
    } else {
        if (highPerc < Number(commission.value)) {
            highPerc = Number(commission.value);
            highPercPrice = Number(commission.price);
        }
    }
    let totalProfit = 0;
    if (highPerc > 0) {
        totalProfit = (highPerc*(highPercPrice/100));
    }
    if (highFixed > 0) {
        if (highFixed > totalProfit) return currency + Number(highFixed).toFixed(2).toString();
    }
    return currency + Number(totalProfit).toFixed(2).toString();
}


////////// reducer functions

export const login = user => {
    return {
        type: 'LOGIN',
        payload: {
            info: user
        }
    };
};
  
export function logout() {
    return {
        type: 'LOGOUT',
        payload: null
    };
}

export const updateMetaTags = (tags) => {
    return {
        type: "SET_TAGS",
        payload: {
            tags
        }
    }
}

export const resetMetaTags = () => {
    return {
        type: "RESET_TAGS"
    }
}