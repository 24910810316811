import React from 'react';
import { connect } from 'react-redux';
import ReactQueryParams from 'react-query-params';
import { HomeLoading } from './../home/loading';
import { getProducts, updateMetaTags } from './../actions/';
import { ProductListing } from './../products/listing';
import { tags } from './tags';

class SearchProducts extends ReactQueryParams {

    state = {
        loading: true,
        hits: [],
        page: 0,
        query: this.queryParams.q || '',
        searchTags: [],
        showTags: false,
        hasPrev: false,
        hasNext: false
    }

    searchProducts = async() => {
        window.$(".scroll-to-top").click();
        this.setState({loading: true}, async () => {
            let results = await getProducts(18, this.state.page, this.state.query, this.state.searchTags);
            let hasNext = (results.length === 18) ? true : false;
            let hasPrev = (this.state.page > 0) ? true : false;
            this.setState({
                loading: false,
                hits: results,
                hasNext: hasNext,
                hasPrev: hasPrev
            });
        });
    }

    componentWillReceiveProps = (prev, current) => {
        if (prev !== current) {
            this.setState({query: this.queryParams.q || ''});
            this.searchProducts();
        }
    }

    componentDidMount = async () => {
        this.props.updateMetaTags({
            title: 'Search for Shopify products to dropship - BoxUp',
            description: "Search and choose from products all around the world. Check commission, available shipping locations and more.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'search, products, dropship, boxup, Shopify'
                }
            }
        });
        this.searchProducts();
    }
    

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.searchProducts();
        }
    }

    pageChange = (n) => {
        let page = this.state.page + n;
        this.setState({page}, () => {
            this.searchProducts();
        });
    }

    showTags = () => {
        this.setState({showTags: !this.state.showTags});
    }

    updateTags = (tag) => {
        let searchTags = this.state.searchTags;
        let hasTag = searchTags.filter(item => item === tag);
        if (!hasTag.length > 0) {
            searchTags.push(tag);
        } else {
            searchTags = searchTags.filter(item => item !== tag);
        }
        this.setState({searchTags}, () => {
            this.searchProducts();
        });
    }
    
    render() {
        return (
            <>
                <section className="offer-category bg-primary text-center">
                    <div className="container">
                        <div className="row py-lg-4">
                            <div className="col-md-12">
                                <h6 className="text-white mg-b-0">Product Search</h6>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-padding">
                    <div className="container pt-lg-4">
                        <div className="row">
                            <div className="col-xl-3 col-sm-4">
                                <div className="filters shadow-sm rounded bg-white mb-4">
                                    <div className="filters-header border-bottom pl-4 pr-4 pt-3 pb-3">
                                        <h5 className="m-0">Filter By</h5>
                                    </div>
                                    <div className="filters-body">
                                        <div id="accordion">
                                            <div className="filters-card border-bottom p-4">
                                                <div className="filters-search mb-3">
                                                    <div className="form-group">
                                                        <i className="icofont-search"></i>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Search products"
                                                            value={this.state.query}
                                                            onChange={(e) => this.setState({query: e.target.value})}
                                                            onKeyDown={this._handleKeyDown}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="filters-card-header" id="headingTwo">
                                                    <h6 className="mb-0">
                                                        <a href="#" className="btn-link" data-toggle="collapse" data-target="#collapsetwo"
                                                            aria-expanded="true" aria-controls="collapsetwo">
                                                            Product Tags <i className="icofont-thin-down float-right"></i>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapsetwo" className="collapse show" aria-labelledby="headingTwo"
                                                data-parent="#accordion">
                                                    <div className="filters-card-body card-shop-filters">
                                                        {tags.sort().map((tag, i) => {
                                                            let cls = (i > 5 && !this.state.showTags) ? 'hide-tag' : '';
                                                            let isChecked = (this.state.searchTags.filter(item => item === tag).length > 0) ? true : false;
                                                            return <div className={`custom-control custom-checkbox ${cls}`} key={`tag_${tag}`}>
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id={`tag_${tag}`}
                                                                    checked={isChecked}
                                                                    onChange={() => this.updateTags(tag)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={`tag_${tag}`}>{tag}</label>
                                                            </div>
                                                        })}
                                                        <div className="mt-2">
                                                            <a href="#this" onClick={this.showTags} className="link">See {(this.state.showTags) ? 'less' : 'all'}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-sm-8">
                                <div className="row">
                                    {this.state.loading &&
                                    <HomeLoading col={"4"}/>
                                    }
                                    {!this.state.loading && this.state.hits.map((item) => {
                                        return <ProductListing col={4} item={item} key={item.objectID}/>
                                    })
                                    }
                                    {this.state.hits.length === 0 && !this.state.loading &&
                                    <div className="col-xl-12 text-center mt-5">
                                        <span className="btn btn-primary shadow btn-sm show-more-btn mb-2">No results found</span>
                                        <p>Try searching something else.</p>
                                    </div>
                                    }
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <nav aria-label="breadcrumb">
                                            <div style={{float: 'left'}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-light" 
                                                    disabled={!this.state.hasPrev}
                                                    onClick={() => this.pageChange(-1)}
                                                >
                                                    Previous
                                                </button>
                                            </div>
                                            <div style={{float: 'right'}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-primary" 
                                                    disabled={!this.state.hasNext}
                                                    onClick={() => this.pageChange(1)}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    actions
)(SearchProducts);