import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { login, logout, updateMetaTags } from './../actions/';
import { fb, db } from './../actions/settings';

class Register extends React.Component {

    state = {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        error: ''
    }

    componentDidMount = async() => {
        console.log(this.props.user.loggedIn)
        if (this.props.user.loggedIn && this.props.user.info) {
            this.props.logout();
            this.props.history.push("/account/");
        }
        window.$(".scroll-to-top").click();
        this.props.updateMetaTags({
            title: 'Login to BoxUp',
            description: "Register to the BoxUp dropshipping platform.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Register, dropship, boxup, Shopify'
                }
            }
        });
    }

    submit = async(e) => {
        let { firstname, lastname, email, password } = this.state;
        e.preventDefault();
        if (password.length < 8) this.setState({
            loading: false, 
            error: "Please enter a password at least 8 characters long"
        });
        this.setState({
            loading: true,
            error: ''
        }, async() => {
            try {
                let data = await fb
                    .auth()
                    .createUserWithEmailAndPassword(email, password);
                if (data) {
                    let data = await fb.auth().signInWithEmailAndPassword(email, password);
                    let user = {
                        id: data.user.uid,
                        firstname,
                        lastname,
                        email,
                        paid: false,
                        stripeConnected: false,
                        newsletter: true
                    }
                    await db
                        .collection("Affiliates")
                        .doc(data.user.uid)
                        .set(user);
                    this.props.login(user);
                    this.props.history.goBack();
                } else {
                    this.setState({regError: "Unable to complete registration, try again.", loading: false});
                }
            } catch(e) {
                this.setState({error: e.message, loading: false});
            }
        });
    }
    
    render() {
        return (
            <>
                <div className="py-5 bg-gray-600">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center py-lg-5">
                                <h1 className="text-white font-weight-light mt-0">
                                    Become a BoxUp Dropshipper
                                </h1>
                                <Link to="/account/login" className="btn btn-lg btn-outline-light mr-2">Already have an account?</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-padding osahan-pricing">
                    <div className="container">
                        <div className="row py-lg-4">
                            <div className="login-main-left">
                                <form onSubmit={this.submit}>
                                    <div className="form-group floating-label-form-group">
                                        <label>First Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter your first name"
                                            value={this.state.firstname}
                                            required
                                            name="firstname"
                                            onChange={e => this.setState({firstname: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group floating-label-form-group">
                                        <label>Last Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter your last name"
                                            value={this.state.lastname}
                                            required
                                            name="lastname"
                                            onChange={e => this.setState({lastname: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group floating-label-form-group">
                                        <label>Email Address</label>
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            placeholder="Enter your email address"
                                            value={this.state.email}
                                            required
                                            name="email"
                                            onChange={e => this.setState({email: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group floating-label-form-group">
                                        <label>Password</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            placeholder="Enter your password"
                                            value={this.state.password}
                                            required
                                            name="password"
                                            onChange={e => this.setState({password: e.target.value})}
                                        />
                                    </div>
                                    {this.state.error !== "" &&
                                    <div className="alert alert-danger mt-4" role="alert">
                                        {this.state.error}
                                    </div>
                                    }
                                    <div className="text-center mt-5">
                                        <p className="light-gray">By registering, you agree to our {` `}
                                            <Link to="/terms">Terms</Link> and <Link to="/privacy">Privacy Policy</Link>
                                        </p>
                                    </div>
                                    {!this.state.loading && 
                                    <button type="submit" className="btn btn-primary btn-block btn-lg mt-4">Register</button>
                                    }
                                    {this.state.loading && 
                                    <button type="button" className="btn btn-primary btn-block btn-lg mt-4">
                                        <ClipLoader
                                            size={24}
                                            color={"#ffffff"}
                                            loading={this.state.loading}
                                        />
                                    </button>
                                    }
                                </form>
                                <div className="text-center mt-5">
                                    <p className="light-gray">Forgot your password? <Link to="/account/reset">Reset</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login,
    logout,
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Register);