import React from 'react';
import { connect } from 'react-redux';
import AccountTabs from './tabs';
import ClipLoader from "react-spinners/ClipLoader";
import { confirmSubscription, login } from './../actions/';
import ReactQueryParams from 'react-query-params';

class Success extends ReactQueryParams {

    state = {
        sessionId: this.queryParams.session_id,
        loading: true
    }

    componentDidMount = async() => {
        window.$(".scroll-to-top").click();
        let { sessionId } = this.state;
        if (!sessionId) {
            this.props.history.push("/account/failed");
        }
        let data = await confirmSubscription(sessionId, this.props.user.info.id);
        let paid = (data.paid);
        const userData = { ...this.props.user.info, paid: paid };
        this.props.login(userData);
        this.props.history.push("/account/subscribe");
    }
    
    render() {
        return (
            <>
                <section className="user-panel-body py-5">
                    <div className="container">
                        <div className="row">
                            <AccountTabs/>
                            <div className="col-xl-9 col-sm-8">
                                <div className="row">
                                    <div className="col-md-12 w-100 text-center pt-4">
                                        <ClipLoader
                                            size={32}
                                            color={"#000000"}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Success);