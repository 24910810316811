import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { sendContact } from './../actions/';

class Contact extends React.Component {

    state = {
        loading: false,
        name: '',
        email: '',
        subject: '',
        message: '',
        error: '',
        success: false
    }

    componentDidMount = async() => {
        window.$(".scroll-to-top").click();
        let user = this.props.user.info;
        if (user) {
            this.setState({
                name: user.firstname + ' ' + user.lastname,
                email: user.email
            });
        }
    }

    submit = async(e) => {
        e.preventDefault();
        let { name, email, subject, message } = this.state;
        this.setState({loading: true, error: ''}, async() => {
            await sendContact({
                "name": name,
                "email": email,
                "subject": subject,
                "message": message
            });
            this.setState({
                success: true,
                loading: false,
                subject: '',
                message: ''
            });
        });
    }
    
    render() {
        return (
            <>
                <section className="offer-dedicated-header bg-primary pb-4">
                    <div className="container">
                        <div className="row py-lg-5">
                            <div className="col-md-6 mx-auto text-center">
                                <h1 className="text-white mb-3">Contact Us</h1>
                                <h6 className="text-white-50 mb-0">
                                    Please get in touch if you need any support.
                                </h6>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-padding">
                    <div className="container">
                        <div className="row py-lg-4">
                            <div className="col-md-10 mx-auto text-center">
                                <div className="text-left rating-review-select-page">
                                    <h6 className="mb-3 text-gray-800 font-weight-normal">
                                        If you're experiencing any technial difficulty, please provide as much detail as possible.
                                    </h6>
                                    {this.state.error !== "" &&
                                    <div className="alert alert-danger" role="alert">
                                        <p>{this.state.error}</p>
                                    </div>
                                    }
                                    {this.state.success &&
                                    <div className="mt-4 mb-4 text-center w-100">
                                        <div className="alert alert-success" role="alert">
                                            <p>Thank you for contacting us. We will reply as soon as possible.</p>
                                        </div>
                                    </div>
                                    }
                                    {!this.state.loading && 
                                    <form onSubmit={this.submit}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="js-form-message">
                                                    <label className="form-label">
                                                        Your Name {` `}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="form-group">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="name" 
                                                            required
                                                            value={this.state.name}
                                                            onChange={e => this.setState({name: e.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="js-form-message">
                                                    <label className="form-label">
                                                        Email Address {` `}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="form-group">
                                                        <input 
                                                            type="email" 
                                                            className="form-control" 
                                                            name="email" 
                                                            required
                                                            value={this.state.email}
                                                            onChange={e => this.setState({email: e.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="js-form-message">
                                                    <label className="form-label">
                                                        Subject {` `}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="form-group">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            name="subject" 
                                                            required
                                                            value={this.state.subject}
                                                            onChange={e => this.setState({subject: e.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Message {` `}<span className="text-danger">*</span></label>
                                            <textarea 
                                                className="form-control" 
                                                rows="6"
                                                name="message"
                                                maxLength="400"
                                                value={this.state.message}
                                                onChange={e => this.setState({message: e.target.value})}
                                            ></textarea>
                                        </div>
                                        <div className="row pt-lg-4">
                                            <div className="col-sm-12 mx-auto w-100">
                                                <hr/>
                                                <div className="float-left">
                                                    <button type="button" onClick={this.props.history.goBack} className="btn btn-light"> Back </button>
                                                </div>
                                                <div className="text-right">
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-primary"
                                                    > Send Message </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    }
                                    {this.state.loading &&
                                    <div className="mt-4 mb-4 text-center w-100">
                                        <ClipLoader
                                            size={36}
                                            color={"#000"}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(withRouter(Contact));