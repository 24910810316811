import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { logout } from './actions/';
import DocumentMeta from 'react-document-meta';

class Layout extends React.Component {

    state = {
        search: ''
    }

    logout = () => {
        this.props.logout();
        this.props.history.push("/account/login");
    }

    search = (e) => {
        e.preventDefault();
        this.props.history.push("/search/products?q=" + this.state.search);
    }
    
    render() {
        let user = this.props.user.info || {};
        let firstname = (user) ? user.firstname : 'Register/';
        let lastname = (user) ? user.lastname : 'Login';
        return (
            <DocumentMeta {...this.props.meta.tags}>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div id="content">
                            <nav className="navbar navbar-expand-lg navbar-light bg-white navbar-light shadow-sm topbar osahan-top-main">
                                <Link className="navbar-brand" to="/">
                                    <img className="img-fluid w-40" src="/assets/images/logo-200.png" alt="BoxUp Logo"/>
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mr-auto">
                                        <li className="nav-item active">
                                            <Link className="nav-link" to="/"><i className="fas fa-home"></i></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/search/products?q=">
                                                <span>Products</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/search/stores?q=">
                                                <span>Stores</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/account/">
                                                <span>Account</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <ul className="navbar-nav ml-auto flex-direction-row">
                                    <li className="nav-item dropdown no-arrow d-sm-none">
                                        <div className="dropdown-menu dropdown-menu-right p-3 shadow-sm border-0 animated--grow-in"
                                            aria-labelledby="searchDropdown">
                                            <form onSubmit={this.search} className="form-inline mr-auto w-100 navbar-search">
                                                <div className="input-group">
                                                    <input 
                                                        type="text" 
                                                        className="form-control bg-light border-0 small"
                                                        value={this.state.search}
                                                        onChange={(e) => this.setState({search: e.target.value})}    
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="fas fa-search fa-sm"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                    <div className="topbar-divider d-none d-sm-block"></div>
                                    <li className="nav-item dropdown no-arrow">
                                        {user.id &&
                                        <>
                                        <Link to="/account/" className="nav-link dropdown-toggle pr-0" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="mr-2 d-none d-lg-inline text-gray-600">
                                                {firstname}{` `}{lastname}
                                            </span>
                                        </Link>
                                        <div className="dropdown-menu dropdown-menu-right shadow-sm border-0 animated--grow-in"
                                            aria-labelledby="userDropdown">
                                            <Link to="/account/" className="dropdown-item">
                                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-500-400"></i>
                                                My Account
                                            </Link>
                                            <div className="dropdown-divider"></div>
                                            <a onClick={this.logout} className="dropdown-item cursor-pointer">
                                                <i className="fas fa-arrow-left fa-sm fa-fw mr-2 text-gray-500-400"></i>
                                                Logout
                                            </a>
                                        </div>
                                        </>
                                        }
                                        {!user.id &&
                                        <>
                                        <Link to="/account/login" className="nav-link dropdown-toggle pr-0" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="mr-2 d-none d-lg-inline text-gray-600">Login/Register</span>
                                        </Link>
                                        <div className="dropdown-menu dropdown-menu-right shadow-sm border-0 animated--grow-in"
                                            aria-labelledby="userDropdown">
                                            <Link to="/account/register" className="dropdown-item">
                                                <i className="fas fa-user-plus fa-sm fa-fw mr-2 text-gray-500-400"></i>
                                                Register
                                            </Link>
                                            <div className="dropdown-divider"></div>
                                            <Link to="/account/login" className="dropdown-item">
                                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-500-400"></i>
                                                Login
                                            </Link>
                                        </div>
                                        </>
                                        }
                                    </li>
                                </ul>
                            </nav>
                            {this.props.children}
                            <section className="footer section-padding">
                                <div className="container">
                                    <div className="row py-lg-4">
                                        <div className="col-xl-2 col-sm-6">
                                            <div className="footer-brand">
                                            <img className="img-fluid" src="/assets/images/grey-200.png" alt="user"/>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-sm-6">
                                            <h6 className="text-gray-900">BOXUP AFFILIATES</h6>
                                            <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/search/products">Search Products</Link></li>
                                            <li><Link to="/account/register">Register</Link></li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-2 col-sm-6">
                                            <h6 className="text-gray-900">HOW IT WORKS</h6>
                                            <ul>
                                            <li><Link to="/how-it-works">Our Guide</Link></li>
                                            <li><Link to="/dropshipping">What is Dropshipping?</Link></li>
                                            <li><a target="_blank" href="https://apps.shopify.com">Add Your Products</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-2 col-sm-6">
                                            <h6 className="text-gray-900">BOXUP</h6>
                                            <ul>
                                            <li><Link to="/terms">Terms & Conditions</Link></li>
                                            <li><Link to="/privacy">Privacy Policy</Link></li>
                                            <li><Link to="/support">Support</Link></li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-4 col-sm-6">
                                            <h6 className="text-gray-900">Want to add your products?</h6>
                                            <small>Install our Shopify App and do just that!</small>
                                            <div className="app mt-2 pt-2">
                                            <a href="https://www.shopify.com/?ref=applow" target="_blank">
                                                <img className="img-fluid shopify-logo" src="/assets/images/shopify.png" alt="Shopify app"/>
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="py-4 bg-white osahan-copyright">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="d-flex align-items-center justify-content-between">
                                            <p className="m-0 text-secondary">Copyright © BoxUp 2020</p>
                                            <p className="m-0">
                                                <Link to="/privacy" className="text-secondary">Privacy Policy</Link>
                                                &nbsp; · &nbsp;
                                                <Link to="/terms" className="text-secondary">Terms &amp; Conditions</Link>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <a className="scroll-to-top rounded-circle" href="#page-top">
                    <i className="fas fa-angle-up"></i>
                </a>
            </DocumentMeta>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    logout
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Layout));