import React from 'react';
import { connect } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";
import AccountTabs from './tabs';
import ReactQueryParams from 'react-query-params';
import { createStripeAccount, login, createSession } from './../actions/';

class Subscribe extends ReactQueryParams {

    state = {
        code: this.queryParams.code || '',
        loading: true,
        error: (this.queryParams.error === "payment") ? 'Error taking payment, please try again.' : ''
    }

    componentDidMount = async() => {
        window.$(".scroll-to-top").click();
        if (!this.props.user.loggedIn || !this.props.user.info) {
            this.props.history.push("/account/login");
        } else {
            if (this.state.code !== "" && !this.props.user.info.stripeConnected) {
                this.setState({loading: true, error: ''}, async() => {
                    try {
                        let data = await createStripeAccount(
                            this.props.user.info.id, 
                            this.state.code,
                            this.props.user.info.email
                        );
                        let connected = (data.stripeConnected);
                        const userData = { ...this.props.user.info, stripeConnected: connected };
                        this.props.login(userData); 
                        this.setState({loading: false});
                    } catch(e) {
                        console.log(e);
                        this.setState({
                            loading: false,
                            error: e.message
                        });
                    }
                });
            } else {
                this.setState({loading: false});
            }
        }
    }

    createAccount = async() => {
        window.location = `https://connect.stripe.com/express/oauth/authorize` + 
            `?redirect_uri=http://localhost:3000/account/subscribe` + 
            `&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&state=${this.props.user.info.id}` +
            `&stripe_user[email]=${this.props.user.info.email}`;
    }

    createPayment = async() => {
        this.setState({loading: true}, async() => {
            if (this.props.user.info.stripeConnected) {
                let session = await createSession(this.props.user.info.id);
                let stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK);
                stripe.redirectToCheckout({
                    sessionId: session.id
                });
            } else {
                this.setState({
                    loading: false,
                    error: 'Please complete step 1 first.'
                });
            }
        });
    }
    
    render() {
        let user = this.state.user;
        return (
            <>
                <section className="user-panel-body py-5">
                    <div className="container">
                        <div className="row">
                            <AccountTabs/>
                            <div className="col-xl-9 col-sm-8">
                                <div className="user-panel-body-right">
                                    <div className="user-panel-tab-view mb-4">
                                        <h5 className="mb-0 text-gray-900 mb-3">Subscribing to BoxUp</h5>
                                        {this.state.error !== "" &&
                                        <div className="alert alert-danger mt-4" role="alert">
                                            {this.state.error}
                                        </div>
                                        }
                                        {!this.state.loading && 
                                        <>
                                        <div className="shadow-sm rounded overflow-hidden mb-4">
                                            <div className="card border-0 shadow-sm">
                                                <div className="card-header py-3">
                                                    <h6 className="m-0 font-weight-bold text-primary">Step 1: Payment Account</h6>
                                                </div>
                                                <div className="card-body">
                                                    <p>
                                                        We use Stripe to securely send you payments once you earn commission. 
                                                        Create your account account by clicking the button below.
                                                    </p>
                                                    {!this.props.user.info.stripeConnected &&
                                                        <button 
                                                            className="btn btn-primary mt-4" 
                                                            type="button"
                                                            onClick={this.createAccount}
                                                        > 
                                                            Setup Payment Account  
                                                        </button>
                                                    }
                                                    {this.props.user.info.stripeConnected &&
                                                        <span className="btn btn-success btn-icon-split">
                                                            <span className="icon text-white-50">
                                                                <i className="fas fa-check"></i>
                                                            </span>
                                                            <span className="text">Payment Account Setup</span>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm rounded overflow-hidden mb-4">
                                            <div className="card border-0 shadow-sm">
                                                <div className="card-header py-3">
                                                    <h6 className="m-0 font-weight-bold text-primary">Step 2: Setup Subscription</h6>
                                                </div>
                                                <div className="card-body">
                                                    <p>
                                                        To earn commission per sale, you'll need to have an active subscription.
                                                        This is $19 USD per month.
                                                    </p>
                                                    {!this.props.user.info.paid && 
                                                        <button 
                                                            className="btn btn-primary mt-2" 
                                                            type="button"
                                                            onClick={this.createPayment}
                                                            disabled={(!this.props.user.info.stripeConnected)}
                                                        > 
                                                            {(this.props.user.info.stripeConnected) ? 'Setup Subscription' : 'Complete Step 1 first'}
                                                        </button>
                                                    }
                                                    {this.props.user.info.paid &&
                                                        <span className="btn btn-success btn-icon-split">
                                                            <span className="icon text-white-50">
                                                                <i className="fas fa-check"></i>
                                                            </span>
                                                            <span className="text">Subscription Active</span>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        }
                                        {this.state.loading &&
                                        <div className="w-100 text-center pt-4">
                                            <ClipLoader
                                                size={32}
                                                color={"#000000"}
                                                loading={this.state.loading}
                                            />
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    login
}
  
export default connect(
    mapStateToProps,
    actions
)(Subscribe);