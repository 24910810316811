import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { fb, db } from './../actions/settings';
import { login, updateMetaTags } from './../actions/';

class Login extends React.Component {

    state = {
        email: '',
        password: '',
        loading: false,
        error: ''
    }

    componentDidMount = async() => {
        if (this.props.user.loggedIn && this.props.user.info) this.props.history.push("/account/");
        window.$(".scroll-to-top").click();
        this.props.updateMetaTags({
            title: 'Login to BoxUp',
            description: "Login to the BoxUp dropshipping platform.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Login, dropship, boxup, Shopify'
                }
            }
        });
    }

    submit = async(e) => {
        let { email, password } = this.state;
        e.preventDefault();
        this.setState({
            loading: true,
            error: ''
        }, async() => {
            try {
                let data = await fb.auth().signInWithEmailAndPassword(email, password);
                let uid = data.user.uid;
                let doc = await db.collection("Affiliates")
                    .doc(uid)
                    .get();
                if (doc.exists) {
                    this.props.login({
                        id: uid,
                        firstname: doc.data().firstname,
                        lastname: doc.data().lastname,
                        paid: doc.data().paid,
                        stripeConnected: doc.data().stripeConnected,
                        email: doc.data().email
                    });
                    this.props.history.push("/account/");
                } else {
                    this.setState({
                        loading: false,
                        error: 'Unable to log in with those details.'
                    });
                }
            } catch(e) {
                this.setState({
                    loading: false,
                    error: 'Unable to log in with those details.'
                });
            }
        });
    }
    
    render() {
        return (
            <>
                <div className="py-5 bg-gray-600">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center py-lg-5">
                                <h1 className="text-white font-weight-light mt-0">
                                    Login to your account
                                </h1>
                                <Link to="/account/register" className="btn btn-lg btn-outline-light mr-2">Need a new account?</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-padding osahan-pricing">
                    <div className="container">
                        <div className="row py-lg-4">
                            <div className="login-main-left">
                                <form onSubmit={this.submit}>
                                    <div className="form-group floating-label-form-group">
                                        <label>Email Address</label>
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            placeholder="Enter your email address"
                                            value={this.state.email}
                                            required
                                            name="email"
                                            onChange={e => this.setState({email: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group floating-label-form-group">
                                        <label>Password</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            placeholder="Enter your password"
                                            value={this.state.password}
                                            required
                                            name="password"
                                            onChange={e => this.setState({password: e.target.value})}
                                        />
                                    </div>
                                    {this.state.error !== "" &&
                                    <div className="alert alert-danger mt-4" role="alert">
                                        {this.state.error}
                                    </div>
                                    }
                                    {!this.state.loading && 
                                    <button type="submit" className="btn btn-primary btn-block btn-lg mt-4">Login</button>
                                    }
                                    {this.state.loading && 
                                    <button type="button" className="btn btn-primary btn-block btn-lg mt-4">
                                        <ClipLoader
                                            size={24}
                                            color={"#ffffff"}
                                            loading={this.state.loading}
                                        />
                                    </button>
                                    }
                                </form>
                                <div className="text-center mt-5">
                                    <p className="light-gray">Forgot your password? <Link to="/members/reset">Reset</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    login,
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    actions
)(Login);