import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Layout from './layout';

// layout routes
import Home from './home/';
import SearchProducts from './products/search';
import ProductDetails from './products/details';
import SearchStores from './stores/search';
import StoreDetails from './stores/details';

import Account from './account/details';
import Login from './account/login';
import Register from './account/register';
import Subscribe from './account/subscribe';
import Success from './account/success';
import Payments from './account/payments';
import Orders from './account/orders';

import Terms from './pages/terms';
import Privacy from './pages/privacy';
import HowItWorks from './pages/guide';
import Dropshipping from './pages/dropshipping';
import Contact from './pages/contact';

class App extends React.Component {

    render() {
        return (
            <Router>
                <Switch>
                    <Layout>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/search/products" component={SearchProducts}/>
                        <Route exact path="/search/stores" component={SearchStores}/>
                        <Route exact path="/products/:shop/:handle" component={ProductDetails}/>
                        <Route exact path="/stores/:domain" component={StoreDetails}/>

                        <Route exact path="/account/" component={Account}/>
                        <Route exact path="/account/login" component={Login}/>
                        <Route exact path="/account/register" component={Register}/>
                        <Route exact path="/account/subscribe" component={Subscribe}/>
                        <Route exact path="/account/success" component={Success}/>
                        <Route exact path="/account/payments" component={Payments}/>
                        <Route exact path="/account/orders" component={Orders}/>

                        <Route exact path="/dropshipping" component={Dropshipping}/>
                        <Route exact path="/how-it-works" component={HowItWorks}/>
                        <Route exact path="/terms" component={Terms}/>
                        <Route exact path="/privacy" component={Privacy}/>
                        <Route exact path="/support" component={Contact}/>
                    </Layout>
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(App);