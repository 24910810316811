import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const ProductLoading = ({col}) => {
    return <>
        <section className="offer-dedicated-header bg-white py-4">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="float-right">
                            <Skeleton height="50px" heightRandomness="0"/>
                        </div>
                        <h2 className="text-gray-900"><Skeleton/></h2>
                        <p className="mb-0 text-gray-700">
                            <Skeleton/>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="offer-dedicated-body section-padding">
            <div className="container">
                <div className="row pt-lg-4">
                    <div className="col-xl-8 col-sm-12">
                        <div className="offer-dedicated-body-left">
                            <h5 className="mb-3 text-gray-900"><Skeleton color="#969fab"/></h5>
                            <div className="row">
                                <h5 className="mb-3 mt-3 col-md-12 text-gray-900"><Skeleton /><small
                                    className="h6 text-gray-800"><Skeleton/></small>
                                </h5>
                                <div className="col-md-12">
                                    <div className="bg-white rounded shadow-sm mb-4">
                                        <div className={`gold-members lh-40 p-3`}>
                                            <div className="float-right">
                                                <div className="star">
                                                    <span className="badge badge-large">
                                                        <b><Skeleton/></b> <Skeleton/>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="mr-3">
                                                    <Skeleton/>
                                                </div>
                                                <div className="media-body">
                                                    <h6 className="mb-0 text-gray-800"><Skeleton/></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="bg-white rounded shadow-sm mb-4">
                                        <div className={`gold-members lh-40 p-3`}>
                                            <div className="float-right">
                                                <div className="star">
                                                    <span className="badge badge-large">
                                                        <b><Skeleton/></b> <Skeleton/>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="mr-3">
                                                    <Skeleton/>
                                                </div>
                                                <div className="media-body">
                                                    <h6 className="mb-0 text-gray-800"><Skeleton/></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}