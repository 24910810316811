import React from 'react';
import { Link } from 'react-router-dom';
import { differenceInDays } from './../actions/';

export const ProductListing = ({col, item}) => {
    let cls = (col === 3) 
        ? 'col-xl-3 col-sm-6 mb-4'
        : 'col-xl-4 col-sm-6 mb-4';
    let link = `/products/${item.shopName}/${item.handle}`;
    let dayDiff = differenceInDays(new Date(), new Date(item.createdDate*1000));
    let crrncy = (item.commission.length > 0) ? (item.commission[0].formattedPrice).toString().substr(0,1) : '';
    let min = Math.min(...item.commission.map(item => item.price));
    let minPrice = (min) ? Number(min).toFixed(2) : 'Click to view';
    return <div className={cls}>
        <div className="custom-card shadow-sm h-100">
            <div className="custom-card-image">
                <Link to={{pathname: link, state: {commission: item.commission}}}>
                    <img className="img-fluid item-img" src={item.image}/>
                </Link>
                <div className="button-g-btn button-g-btn-up">
                    <span>Earn up to {item.maxComm}/sale</span>
                </div>
            </div>
            <div className="p-3 pt-4">
                <div className="custom-card-body">
                    <h6 className="mb-3">
                        <Link className="text-gray-900" to={{pathname: link, state: {commission: item.commission}}}>
                        {item.title}
                        </Link>
                    </h6>
                    <p className="text-gray-500">
                        <i className="icofont-clock-time"></i> 
                        {` `} Added {dayDiff}
                    </p>
                </div>
                <div className="custom-card-footer d-flex align-items-center">
                    <span className="text-danger">
                        <i className="icofont-tag"></i> 
                        {` `}From {crrncy}{minPrice}
                    </span>
                    <Link 
                        className="btn btn-sm btn-white ml-auto" 
                        to={{pathname: link, state: {commission: item.commission}}}
                    >
                        View Product
                    </Link>
                </div>
            </div>
        </div>
    </div>
}