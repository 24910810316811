import React from 'react';
import { connect } from 'react-redux';

class Dropshipping extends React.Component {

    componentDidMount = async() => {
        window.$(".scroll-to-top").click();
    }
    
    render() {
        return (
            <>
            <section className="offer-category bg-primary text-center">
                <div className="container">
                    <div className="row py-lg-4">
                        <div className="col-md-12">
                            <h6 className="text-white mg-b-0">Dropshipping Explained</h6>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-padding">
                <div className="container pt-lg-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="box border rounded bg-white mb-4">
                                <div className="p-4 d-flex align-items-center">
                                    <img src="/assets/images/dropship.png" height="56"/>
                                    <div className="ml-4">
                                        <h5 className="text-gray-900 mb-3 mt-0">What does dropshipping mean?</h5>
                                        <p className="mb-0 text-muted">
                                            A store selling products, using the dropshipping model, doesn't{` `}
                                            need to keep stock of anything. They, instead, pass the order over to the{` `}
                                            original store, that keeps the stock. The original store then ships the product{` `}
                                            to the customer, while the dropshipper either earns a specified amount per sale or {` `}
                                            prices up the product, pocketing the difference.
                                        </p>
                                        <h5 className="text-gray-900 mb-3 mt-4">The benefits of dropshipping</h5>
                                        <p className="mb-2 text-muted">
                                            Dropshipping is a great business model for aspiring entrepreneurs to start {` `}
                                            with because it’s accessible. No need to keep stock of any products and you can {` `}
                                            change your entire catalogue and try something new, without much of a fuss.
                                        </p>
                                        <p className="mb-2 text-muted">
                                            Less capital is required to get started. A traditional retailer will have to invest large {` `}
                                            amounts in inventory. 
                                        </p>
                                        <p className="mb-2 text-muted">
                                            You don't have to be tied down to a specific location, you can manage your business from anywhere.
                                        </p>
                                        <h5 className="text-gray-900 mb-3 mt-4">Is dropshipping a legitimate business?</h5>
                                        <p className="mb-2 text-muted">
                                            Yes. It's a method used by many global retailers to expand reach and customer base, as well as increase sales.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

  
export default connect(
    mapStateToProps
)(Dropshipping);