import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class HowItWorks extends React.Component {

    componentDidMount = async() => {
        window.$(".scroll-to-top").click();
    }
    
    render() {
        return (
            <>
            <section className="offer-category bg-primary text-center">
                <div className="container">
                    <div className="row py-lg-4">
                        <div className="col-md-12">
                            <h6 className="text-white mg-b-0">How It Works</h6>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-padding">
                <div className="container pt-lg-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="box border rounded bg-white mb-4">
                                <div className="p-4 d-flex align-items-center">
                                    <i className="icofont-shopify display-4"></i>
                                    <div className="ml-4">
                                        <h5 className="text-gray-900 mb-3 mt-0">We display products</h5>
                                        <p className="mb-0 text-muted">
                                            Shopify stores add their products to BoxUp and specify how much{` `}
                                            someone can make if they sell each product on their behalf. We refer to this as 'commission' {` `}
                                            and it will be different for each product and, sometimes, different for each variant of the product.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="box border rounded bg-white mb-4">
                                <div className="p-4 d-flex align-items-center">
                                    <i className="icofont-contact-add display-4"></i>
                                    <div className="ml-4">
                                        <h5 className="text-gray-900 mb-3 mt-0">Sign Up to BoxUp</h5>
                                        <p className="mb-0 text-muted">
                                            In order to earn commission, you will need to <Link to="/account/register">sign up</Link> {` `}
                                            to BoxUp and have an active $19/month membership.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="box border rounded bg-white mb-4">
                                <div className="p-4 d-flex align-items-center">
                                    <i className="icofont-bank-transfer-alt display-4"></i>
                                    <div className="ml-4">
                                        <h5 className="text-gray-900 mb-3 mt-0">Link your bank account to get paid</h5>
                                        <p className="mb-0 text-muted">
                                            Before subscribing to the $19 membership, you will need to tell us the country{` `}
                                            of your bank, which bank account you would like to receive payouts to and, in some cases, {` `}
                                            verify your identity. We use <a href="https://stripe.com" target="_blank">Stripe</a> to do this.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="box border rounded bg-white mb-4">
                                <div className="p-4 d-flex align-items-center">
                                    <i className="icofont-search display-4"></i>
                                    <div className="ml-4">
                                        <h5 className="text-gray-900 mb-3 mt-0">You search for products</h5>
                                        <p className="mb-0 text-muted">
                                            Once registered, you can <Link to="/search/products">search for products</Link> to find something you want to sell. {` `}
                                            Once you find a product, click on it to load all the details for that product. You will see all variations of the product, {` `}
                                            product images, available shipping locations and the amount of commission you'll receive per sale.
                                        </p>
                                        <div className="mt-2">
                                            <a 
                                                href="/assets/images/details.png"
                                                data-lightbox="details" 
                                                data-title="Product Details"
                                            >View an example</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box border rounded bg-white mb-4">
                                <div className="p-4 d-flex align-items-center">
                                    <i className="icofont-card display-4"></i>
                                    <div className="ml-4">
                                        <h5 className="text-gray-900 mb-3 mt-0">Selling the product</h5>
                                        <p className="mb-0 text-muted">
                                            On the product details page, you will be given a link (URL) to send your customers to. {` `}
                                            This URL includes <code>ref=YOUR_CHECKOUT_CODE</code> so that we know you are the one who {` `}
                                            directed the customer to the checkout. If the customer purchases the product, you'll be credited {` `}
                                            with the commission. If you're familiar with HTML, you will also see an option to include a JavaScript{` `}
                                            file and a <code>{`<button>`}</code> tag so that the checkout loads in a popup modal, rather than a new page.
                                        </p>
                                        <div className="mt-2">
                                            <a href="/assets/images/sell.png"
                                                data-lightbox="sell" 
                                                data-title="Selling a Product"
                                            >View an example</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box border rounded bg-white mb-4">
                                <div className="p-4 d-flex align-items-center">
                                    <i className="icofont-credit-card display-4"></i>
                                    <div className="ml-4">
                                        <h5 className="text-gray-900 mb-3 mt-0">Get Paid</h5>
                                        <p className="mb-0 text-muted">
                                            We will track all commission you earn and payout frequently. You're not limited on the {` `}
                                            number of sales you can make.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

  
export default connect(
    mapStateToProps
)(HowItWorks);