import React from 'react';
import { Link } from 'react-router-dom';

export const StoreListing = ({item}) => {
    let link = `/stores/${item.shopURL}`;
    return <div id="eo" className="bg-white rounded shadow-sm p-4 mb-4 coin-tiers-block w-100">
        <Link to={link} className="btn btn-success float-right btn-sm"> View Products </Link>
        <h5 className="mb-3 text-gray-900">{item.shopName}</h5>
        <a href={`https://${item.shopURL}`} target="_blank" className="caps-label mb-2">{item.shopURL}</a>
        <p className="mb-4 col-10 p-0">{item.desc}</p>
    </div>
}