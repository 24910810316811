import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactQueryParams from 'react-query-params';
import { getProductDetails, getProduct, getCommission, login } from './../actions/';
import { fb, db } from './../actions/settings';
import { ProductLoading } from './loading';
import L from 'leaflet';
import { geoJSON } from './../actions/geo';
import { CopyBlock, rainbow } from 'react-code-blocks';
import ClipLoader from "react-spinners/ClipLoader";

class ProductDetails extends ReactQueryParams {

    state = {
        loading: true,
        loginLoad: false,
        product: {},
        shipping: [],
        shop: {},
        email: '',
        password: '',
        loginError: ''
    }

    componentDidMount = async () => {
        window.$(".scroll-to-top").click();
        let { shop, handle } = this.props.match.params;
        if (!shop || !handle) {
            this.props.history.goBack();
        } else {
            getProductDetails(shop, handle)
            .then(async(data) => {
                let commission;
                if (!this.props.location.state || !this.props.location.state.commission) {
                    let product = await getProduct(`${data.shop.id}==${data.product.productId}`);
                    commission = product.commission;
                } else {
                    commission = this.props.location.state.commission;
                }
                this.setState({
                    shipping: data.shipping,
                    product: data.product,
                    shop: data.shop,
                    loading: false,
                    commission: commission
                }, () => {
                    window.initOwl();
                    this.drawMap();
                });
            })
            .catch((err) => {
                console.log(err);
                this.props.history.goBack();
            });
        }
    }

    drawMap = () => {
        let countries = [];
        this.state.shipping.map((s) => {
            s.countries.map(c => countries.push(c));
        });
        let map = L.map('gmap_canvas').setView([0, 0], 2);
        L.geoJSON(geoJSON, {
            style: function(feature) {
                let country = feature.properties.name;
                let unavColor = (countries.filter(c => c.code === "*").length > 0) ? '#5cc473' : '#e38484';
                let color = (countries.filter(c => c.name === country).length > 0) ? '#5cc473' : unavColor;
                return {
                    radius: 8,
                    fillColor: color,
                    color: "#000",
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0.8
                };
            }
        }).bindPopup(function(layer) {
            return layer.feature.properties.name;
        }).addTo(map);
    }

    login = async (e) => {
        e.preventDefault();
        this.setState({loginLoad: true, loginError: ''}, async() => {
            let { email, password } = this.state;
            try {
                let user = await fb
                    .auth()
                    .signInWithEmailAndPassword(email, password);
                if (user) {
                    let affiliate = await db.collection("Affiliates")
                        .doc(fb.auth().currentUser.uid)
                        .get();
                    if (affiliate.exists) {
                        this.props.login(affiliate.data());
                        this.setState({loginLoad: false});
                    } else {
                        this.setState({
                            loginLoad: false,
                            loginError: "Unable to locate your account with those details. "+
                                "If you are also a store, please use a different email address"
                        });
                    }
                }
            } catch(e) {
                this.setState({
                    loginLoad: false,
                    loginError: "Unable to locate your account with those details"
                });
            }
        });
    }
    
    render() {
        let { shop, product, commission } = this.state;
        let paid = (this.props.user.info) ? this.props.user.info.paid : false;
        let loggedIn = this.props.user.loggedIn;
        return (
            <>
                {!this.state.loading && 
                <>
                <section className="offer-dedicated-header bg-white py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="float-right">
                                    <Link className="btn btn-primary" to={`/stores/${shop.domain}`}>View Store</Link>
                                    <a className="btn text-primary" target="_blank" href={`https://${shop.domain}`}>Visit Website</a>
                                </div>
                                <h2 className="text-gray-900">{shop.name}</h2>
                                <p className="mb-0 text-gray-700">
                                    <a target="_blank" href={`https://${shop.domain}`}>{shop.domain}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="offer-dedicated-body section-padding">
                    <div className="container">
                        <div className="row pt-lg-4">
                            <div className="col-xl-8 col-sm-12">
                                
                                <div className="offer-dedicated-body-left">
                                    <h5 className="mb-3 text-gray-900">{product.title}</h5>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="owl-carousel owl-theme owl-carousel-two offers-interested-carousel mb-4">
                                                {product.images.map((image, i) => {
                                                    return <div className="item" key={i}>
                                                        <div className="bg-white p-4 border shadow-sm text-center h-100 border-radius">
                                                            <div className="custom-card-image">
                                                                <img className="img-fluid item-img" src={image.src}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h5 className="mb-3 mt-3 col-md-12 text-gray-900">Product Variants <small
                                          className="h6 text-gray-800">{product.variants.length} VARIANT(s)</small></h5>
                                        <div className="col-md-12">
                                            <div className="bg-white rounded shadow-sm mb-4">
                                                {product.variants.map((v, i) => {
                                                    let comm = commission.filter(c => c.id === v.variantId);
                                                    let commValue = (comm[0]) ? getCommission(comm[0]) : '';
                                                    let cls = (i === product.variants.length-1) ? '' : 'border-bottom';
                                                    let available = (v.available) ? 'ui-check text-success' : 'ui-close text-danger';
                                                    return <div className={`gold-members lh-40 p-3 ${cls}`} key={v.variantId}>
                                                        <div className="float-right">
                                                            <div className="star">
                                                                <span className="badge badge-success badge-large">
                                                                    <b>Commission:</b> Earn {commValue} per sale
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="media">
                                                            <div className="mr-3">
                                                                <i className={`icofont-${available} food-item`}></i>
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="mb-0 text-gray-800">{v.title} - {v.formattedPrice} {shop.currency}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div id="address" className="bg-white shadow-sm rounded p-4 mb-4">
                                                <h5 className="mb-4  text-gray-900">Shipping</h5>
                                                <label className="caps-label">Price based shipping</label>
                                                <ul className="dot-list mb-2">
                                                    {this.state.shipping.map((s) => {
                                                        return s.price_based_shipping_rates.map((r, x) => {
                                                            return <li key={x}><b>{s.name}</b> - {r.name} - {r.price} {shop.currency}</li>
                                                        })
                                                    })}
                                                </ul>
                                                <label className="caps-label">Weight based shipping</label>
                                                <ul className="dot-list mb-2">
                                                    {this.state.shipping.map((s) => {
                                                        return s.weight_based_shipping_rates.map((r, x) => {
                                                            return <li key={x}><b>{s.name}</b> - {r.name} - {r.price} {shop.currency}</li>
                                                        })
                                                    })}
                                                </ul>
                                                <p>
                                                <span className="bg-gradient-primary border-radius text-white map-hint-green">
                                                    Available
                                                </span>
                                                <span className="bg-gradient-primary border-radius text-white map-hint-red">
                                                    Unavailable
                                                </span>
                                                </p>
                                                <div className="address-map">
                                                    <div className="mapouter">
                                                        <div className="gmap_canvas" id="gmap_canvas" style={{width: '100%', height: '450px'}}>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h5 className="mb-3 text-gray-900">Dropship This Product</h5>
                                        <div className="border rounded bg-white shadow-sm p-4 mb-4">
                                            {paid && 
                                            <> 
                                            <p className="mb-4 p-0">You can use any method below to sell this product and earn commission.</p>
                                            <div>
                                                <label className="caps-label">Option 1</label>
                                                <p className="mt-3 mb-1">Direct customers to this link to checkout.</p>
                                                <CopyBlock
                                                    text={`https://checkout.boxup.io/${shop.domain}/${product.handle}?ref=${this.props.user.info.id}`}
                                                    language="jsx"
                                                    showLineNumbers={false}
                                                    wrapLines
                                                    theme={rainbow}
                                                />
                                            </div>
                                            <hr/>
                                            <div>
                                                <label className="caps-label">Option 2</label>
                                                <p className="mt-3 mb-1">
                                                    <b>Step 1</b>: Include this Javascript file in the <code>{`<head>`}</code> tag of your website.</p>
                                                <CopyBlock
                                                    text={'<script src="https://cdn.boxup.io/button.js">' +
                                                    '</script>'}
                                                    language="jsx"
                                                    showLineNumbers={false}
                                                    wrapLines
                                                    theme={rainbow}
                                                />
                                                <p className="mt-3 mb-1"><b>Step 2</b>: Paste this <code>{`<button>`}</code> HTML where you want it to appear on your website.</p>
                                                <CopyBlock
                                                    text={'<button ' +
                                                        'data-product="'+product.productId+'" ' +
                                                        'data-store="'+shop.name+'" ' +
                                                        'class="boxup-checkout-button"' +
                                                    '>' +
                                                    '</button>'}
                                                    language="jsx"
                                                    showLineNumbers={false}
                                                    wrapLines
                                                    theme={rainbow}
                                                />
                                            </div>
                                            </>
                                            }
                                            {!loggedIn &&
                                            <div>
                                                <label className="caps-label">Want to dropship? Sign in first.</label>
                                                <form onSubmit={this.login}>
                                                    <div className="form-group">
                                                        <label className="mb-1">Email <small className="text-danger">*</small></label>
                                                        <div className="position-relative icon-form-control">
                                                            <input
                                                                type="email" 
                                                                required
                                                                className="form-control"
                                                                name="email"
                                                                value={this.state.email}
                                                                onChange={(e) => this.setState({email: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="mb-1">Password <small className="text-danger">*</small></label>
                                                        <div className="position-relative icon-form-control">
                                                            <input 
                                                                type="password" 
                                                                required
                                                                name="password"
                                                                className="form-control"
                                                                value={this.state.password}
                                                                onChange={(e) => this.setState({password: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.state.loginError !== "" &&
                                                    <div className="alert alert-danger" role="alert">
                                                        {this.state.loginError}
                                                    </div>
                                                    }
                                                    <div className="form-group">
                                                        {!this.state.loginLoad &&
                                                        <button type="submit" className="btn btn-primary">
                                                            Login
                                                        </button>
                                                        }
                                                        {this.state.loginLoad &&
                                                        <button type="button" className="btn btn-primary">
                                                            <ClipLoader
                                                                size={15}
                                                                color={"#ffffff"}
                                                                loading={this.state.loginLoad}
                                                            />
                                                        </button>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <Link to="/account/register" className="btn btn-light">
                                                            Need an account?
                                                        </Link>
                                                    </div>
                                                </form>
                                            </div>
                                            }
                                            {(this.props.user.info) ? !this.props.user.info.paid : false && this.props.user.loggedIn &&
                                            <div>
                                                <label className="caps-label">Want to dropship? Subscribe first.</label>
                                                <div className="form-group">
                                                    <Link to="/account/subscribe" className="btn btn-primary mt-2">
                                                        Subscribe
                                                    </Link>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>
                }
                {this.state.loading && 
                <ProductLoading />
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails);