import React from 'react';
import { connect } from 'react-redux';
import {PaymentLoading} from "./payment-loading";
import AccountTabs from './tabs';
import { db } from './../actions/settings';
import { login, updateMetaTags } from '../actions';

class Orders extends React.Component {

    state = {
        user: this.props.user.info,
        loading: true,
        orders: [],
    }

    componentDidMount = async() => {
        window.$(".scroll-to-top").click();
        if (!this.props.user.loggedIn || !this.props.user.info.id) {
            this.props.history.push("/account/login");
        } else {
            this.props.updateMetaTags({
                title: 'My Orders - BoxUp Account',
                description: "Orders you've had created by BoxUp checkout.",
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: 'BoxUp, dropship, account, Shopify, orders'
                    }
                }
            });
            let docs = await db.collection(`Affiliates/${this.props.user.info.id}/Orders`).get();
            let orders = [];
            if (docs.size > 0) {
                docs.forEach((doc) => orders.push(doc.data()));
            }
            this.setState({
                orders,
                loading: false
            });
        }
    }

    render() {
        return (
            <>
                <section className="user-panel-body py-5">
                    <div className="container">
                        <div className="row">
                            <AccountTabs/>
                            <div className="col-xl-9 col-sm-8">
                                <div className="user-panel-body-right">
                                    <div className="user-panel-tab-view mb-4">
                                        <div className="shadow-sm rounded overflow-hidden mb-3">
                                            <div className="p-4 bg-white">
                                                <h5 className="mb-0 text-gray-900">My Orders</h5>
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-md-12">
                                                        <div className="mt-2 mb-2">
                                                            <p className="text-muted mt-1">
                                                                A list of all your orders processed by our checkout.
                                                            </p>
                                                            {!this.state.loading && this.state.orders.length === 0 && 
                                                            <p className="mt-2">You have no orders.</p>
                                                            }
                                                        </div>                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm rounded overflow-hidden mb-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {this.state.loading &&
                                                    <PaymentLoading/>
                                                    }
                                                    {!this.state.loading && 
                                                    this.state.orders.map((order, i) => {
                                                        
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    login,
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    actions
)(Orders);