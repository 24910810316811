import React from 'react';
import { connect } from 'react-redux';
import {PaymentLoading} from "./payment-loading";
import AccountTabs from './tabs';
import { db } from './../actions/settings';
import { login, updateMetaTags, getDashboardLink } from '../actions';
import { currencies } from './../actions/currencies';

class Payments extends React.Component {

    state = {
        user: this.props.user.info,
        loading: true,
        payments: [],
        payment: {}
    }

    componentDidMount = async() => {
        window.$(".scroll-to-top").click();
        if (!this.props.user.loggedIn || !this.props.user.info) {
            this.props.history.push("/account/login");
        } else {
            this.props.updateMetaTags({
                title: 'My Payments - BoxUp Account',
                description: "Payments you've made to BoxUp.",
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: 'BoxUp, dropship, account, Shopify, payments'
                    }
                }
            });
            let docs = await db.collection(`Affiliates/${this.props.user.info.id}/Payments`).get();
            let payments = [];
            if (docs.size > 0) {
                docs.forEach((doc) => payments.push(doc.data()));
            }
            this.setState({
                payments,
                loading: false
            });
        }
    }

    viewPayment = (payment) => {
        let title = (payment.subscription) ? 'Subscription Payment' : 'One-off Payment';
        let amnt = (payment.total/100).toFixed(2);
        let currencySymbol = currencies[payment.currency.toUpperCase()].symbol;
        let data = payment;
        data.title = title;
        data.amount = amnt;
        data.symbol = currencySymbol;
        data.paidDate = (new Date(payment.created*1000)).toString();
        this.setState({payment: data}, () => {
            window.$("#payment").modal("show");
        });
    }

    paymentDashboard = async() => {
        this.setState({loading: true}, async() => {
            let link = await getDashboardLink(this.props.user.info.id);
            window.open(link.url);
            this.setState({loading: false});
        });
        
    }

    render() {
        let payment = this.state.payment;
        return (
            <>
                <section className="user-panel-body py-5">
                    <div className="container">
                        <div className="row">
                            <AccountTabs/>
                            <div className="col-xl-9 col-sm-8">
                                <div className="user-panel-body-right">
                                    <div className="user-panel-tab-view mb-4">
                                        <div className="shadow-sm rounded overflow-hidden mb-3">
                                            <div className="p-4 bg-white">
                                                <h5 className="mb-0 text-gray-900">My Payments</h5>
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-md-12">
                                                        <div className="mt-2 mb-4">
                                                            <p className="text-muted">
                                                                A list of all payments made to BoxUp.
                                                            </p>
                                                            {!this.state.loading && this.state.payments.length === 0 && 
                                                            <p className="mt-2">You have made no payments.</p>
                                                            }
                                                            <button className="btn btn-primary"
                                                                onClick={this.paymentDashboard}
                                                            >
                                                                View Payment Dashboard
                                                            </button>
                                                        </div>                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shadow-sm rounded overflow-hidden mb-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {this.state.loading &&
                                                    <PaymentLoading/>
                                                    }
                                                    {!this.state.loading && 
                                                    this.state.payments.map((payment, i) => {
                                                        let title = (payment.subscription) ? 'Subscription Payment' : 'One-off Payment';
                                                        let amnt = (payment.total/100).toFixed(2);
                                                        let currencySymbol = currencies[payment.currency.toUpperCase()].symbol;
                                                        return <div className="bg-white rounded shadow-sm" key={i}>
                                                            <div className="gold-members p-3 border-bottom">
                                                                <a className="btn btn-white btn-sm float-right mt-2" 
                                                                    onClick={() => this.viewPayment(payment)}
                                                                >View</a>
                                                                <div className="media">
                                                                    <div className="media-body">
                                                                        <h6 className="mb-1 text-gray-800">
                                                                            {title} - {currencySymbol}{amnt} {payment.currency.toUpperCase()} 
                                                                        </h6>
                                                                        <p className="text-secondary mb-0">
                                                                            Paid on {(new Date(payment.created*1000)).toString().substr(0,31)}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="modal fade" id="payment" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Payment Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6>{payment.title}</h6>
                            <ul className="list-group list-group-flush mb-4">
                                <li className="list-group-item py-2 px-0 border-0">
                                    Amount: {payment.symbol}{payment.amount}
                                </li>
                                <li className="list-group-item py-2 px-0 border-0">
                                    Currency: {(payment.currency) ? payment.currency.toUpperCase() : ''}
                                </li>
                                <li className="list-group-item py-2 px-0 border-0">
                                    Paid: {payment.paidDate}
                                </li>
                                <li className="list-group-item py-2 px-0 border-0">
                                    Payment Email: {payment.customer_email}
                                </li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                data-dismiss="modal">Close</button>
                            <a className="btn btn-primary" href={payment.hosted_invoice_url} target="_blank">View Invoice</a>
                        </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    login,
    updateMetaTags
}
  
export default connect(
    mapStateToProps,
    actions
)(Payments);