
const defaultState = {
    info: {},
    loggedIn: false
}

export default function user (state = defaultState, action) {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                loggedIn: true,
                info: action.payload.info
            }
        case "LOGOUT":
            return {
                ...state,
                info: {},
                loggedIn: false
            }
        default:
            return state;
    }
}