const defaultState = {
    tags: {
        title: 'BoxUp Dropshipping Platform',
        description: "Earn commission for dropshipping Shopify products from all around the world.",
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'BoxUp, Shopify, dropship, dropshipping'
            }
        }
    }
}

export default function meta (state = defaultState, action) {
    switch(action.type) {
        case "SET_TAGS":
            return {
                ...state,
                tags: action.payload.tags
            }
        case "RESET_TAGS":
            return {
                ...state,
                tags: defaultState.tags
            }
        default:
            return state;
    }
}