import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactQueryParams from 'react-query-params';
import { getStoreDetails, getStoreProducts, login } from './../actions/';
import { ProductLoading } from './../products/loading';
import L from 'leaflet';
import { geoJSON } from './../actions/geo';
import {ProductListing} from './../products/listing';
import {HomeLoading} from './../home/loading';

class StoreDetails extends ReactQueryParams {

    state = {
        loading: true,
        shipping: [],
        products: [],
        page: 0,
        shop: {},
        hasNext: false,
        hasPrev: false,
        loadingProducts: true
    }

    componentDidMount = async () => {
        window.$(".scroll-to-top").click();
        let { domain } = this.props.match.params;
        if (!domain) {
            this.props.history.goBack();
        } else {
            getStoreDetails(domain)
            .then(async(data) => {
                this.setState({
                    shipping: data.shipping,
                    shop: data.shop,
                    loading: false
                }, async() => {
                    await this.getProducts(data.shop.id);
                    this.drawMap();
                });
            })
            .catch((err) => {
                console.log(err);
                this.props.history.goBack();
            });
        }
    }

    getProducts = async() => {
        return this.setState({loadingProducts: true}, async() => {
            let products = await getStoreProducts(4, this.state.page, this.state.shop.id);
            let hasNext = (products.length === 4) ? true : false;
            let hasPrev = (this.state.page > 0) ? true : false;
            this.setState({
                products,
                loading: false,
                loadingProducts: false,
                hasNext,
                hasPrev
            });
        });
    }

    drawMap = () => {
        let countries = [];
        this.state.shipping.map((s) => {
            s.countries.map(c => countries.push(c));
        });
        let map = L.map('gmap_canvas').setView([0, 0], 2);
        L.geoJSON(geoJSON, {
            style: function(feature) {
                let country = feature.properties.name;
                let unavColor = (countries.filter(c => c.code === "*").length > 0) ? '#5cc473' : '#e38484';
                let color = (countries.filter(c => c.name === country).length > 0) ? '#5cc473' : unavColor;
                return {
                    radius: 8,
                    fillColor: color,
                    color: "#000",
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0.8
                };
            }
        }).bindPopup(function(layer) {
            return layer.feature.properties.name;
        }).addTo(map);
    }

    pageChange = (n) => {
        let page = this.state.page + n;
        this.setState({page}, () => {
            this.getProducts();
        });
    }
    
    render() {
        let { shop } = this.state;
        return (
            <>
                {!this.state.loading && 
                <>
                <section className="offer-dedicated-header bg-white py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="float-right">
                                    <a className="btn text-primary" target="_blank" href={`https://${shop.domain}`}>Visit Website</a>
                                </div>
                                <h2 className="text-gray-900">{shop.name}</h2>
                                <p className="mb-2 text-gray-700">
                                    <a target="_blank" href={`https://${shop.domain}`}>{shop.domain}</a>
                                </p>
                                <p className="mb-0 text-gray-700">Currency: {shop.currency}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="offer-dedicated-body section-padding">
                    <div className="container">
                        <div className="row pt-lg-4">
                            <div className="col-sm-12">
                                <h5 className="mb-4  text-gray-900">Products</h5>
                                <div className="row">
                                    {this.state.loadingProducts &&
                                    <HomeLoading col={3}/>
                                    }
                                    {!this.state.loadingProducts && this.state.products.map((item) => {
                                        return <ProductListing col={3} item={item} key={item.objectID}/>
                                    })
                                    }
                                    {!this.state.loadingProducts && this.state.products.length === 0 &&
                                    <div className="col-xl-12 text-center mt-5">
                                        <span className="btn btn-primary shadow btn-sm show-more-btn mb-2">No active products.</span>
                                    </div>
                                    }
                                </div>
                                <hr/>
                                <div className="row mb-4">
                                    <div className="col-sm-12">
                                        <nav aria-label="breadcrumb">
                                            <div style={{float: 'left'}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-light" 
                                                    disabled={!this.state.hasPrev}
                                                    onClick={() => this.pageChange(-1)}
                                                >
                                                    Previous
                                                </button>
                                            </div>
                                            <div style={{float: 'right'}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-primary" 
                                                    disabled={!this.state.hasNext}
                                                    onClick={() => this.pageChange(1)}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                                <div className="offer-dedicated-body-left">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div id="address" className="bg-white shadow-sm rounded p-4 mb-4">
                                                <h5 className="mb-4  text-gray-900">Shipping</h5>
                                                <label className="caps-label">Price based shipping</label>
                                                <ul className="dot-list mb-2">
                                                    {this.state.shipping.map((s) => {
                                                        return s.price_based_shipping_rates.map((r, x) => {
                                                            return <li key={x}><b>{s.name}</b> - {r.name} - {r.price} {shop.currency}</li>
                                                        })
                                                    })}
                                                </ul>
                                                <label className="caps-label">Weight based shipping</label>
                                                <ul className="dot-list mb-2">
                                                    {this.state.shipping.map((s) => {
                                                        return s.weight_based_shipping_rates.map((r, x) => {
                                                            return <li key={x}><b>{s.name}</b> - {r.name} - {r.price} {shop.currency}</li>
                                                        })
                                                    })}
                                                </ul>
                                                <p>
                                                <span className="bg-gradient-primary border-radius text-white map-hint-green">
                                                    Available
                                                </span>
                                                <span className="bg-gradient-primary border-radius text-white map-hint-red">
                                                    Unavailable
                                                </span>
                                                </p>
                                                <div className="address-map">
                                                    <div className="mapouter">
                                                        <div className="gmap_canvas" id="gmap_canvas" style={{width: '100%', height: '450px'}}>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>
                }
                {this.state.loading && 
                <ProductLoading />
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreDetails);