import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from './../actions/';
import { withRouter } from 'react-router-dom';

class AccountTabs extends React.Component {

    state = {
        user: this.props.user.info
    }

    logout = () => {
        this.props.logout();
        this.props.history.push("/account/login");
    }

    checkClass = (tab) => {
        return (this.props.location.pathname === tab) ? 'active' : '';
    }

    render() {
        let user = this.state.user || {
            firstname: '', lastname: '', email: ''
        };
        return <div className="col-xl-3 col-sm-4">
            <div className="user-panel-body-left">
                <div className="bg-white rounded p-4 mb-4 text-center shadow-sm">
                    <h6 className="text-black mb-2 text-gray-900">{user.firstname} {user.lastname}</h6>
                    <p className="m-0">{user.email}</p>
                    <button type="button" onClick={() => this.logout()} className="btn btn-primary btn-block mt-2">
                        <i className="icofont-logout"></i>
                        Logout
                    </button>
                </div>
                <div className="user-panel-sidebar-link mb-4 bg-white rounded shadow-sm overflow-hidden">
                    <Link to="/account/" className={this.checkClass("/account/")}>
                        <i className="icofont-user"></i> 
                        My Details
                    </Link>
                    <Link to="/account/orders" className={this.checkClass("/account/orders")}>
                        <i className="icofont-list"></i> 
                        My Orders
                    </Link>
                    <Link to="/account/payments" className={this.checkClass("/account/payments")}>
                        <i className="icofont-card"></i> 
                        My Payments
                    </Link>
                </div>
            </div>
        </div>
    }

}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    logout
}
    
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AccountTabs));