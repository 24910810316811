import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const HomeLoading = ({col}) => {
    let arr = (col === "3") ? [1,2,3,4] : [1,2,3];
    return arr.map((i) => {
        return <div className={`col-xl-${col} col-sm-6`} key={i}>
            <div className="custom-card shadow-sm h-100">
                <div className="custom-card-image">
                    <a>
                        <Skeleton color="#969fab" height="200px" heightRandomness="0" width={"100%"} widthRandomness="0"/>
                    </a>
                    <div className="button-g-btn button-g-btn-up">
                        <span style={{width: 100+'px', height: 30+'px', display: 'block'}}></span>
                    </div>
                </div>
                <div className="p-3 pt-4">
                    <div className="custom-card-body">
                        <h6 className="mb-3">
                            <Skeleton height={50}/>
                        </h6>
                        <p className="text-gray-500">
                            <Skeleton/>
                        </p>
                    </div>
                    <div className="custom-card-footer d-flex align-items-center">
                        <Skeleton/>
                    </div>
                </div>
            </div>
        </div>
    });
}